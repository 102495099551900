.content {
  color: var(--app-text-color);
  text-align: left;
  margin-bottom: 184px;

  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #292d32;
    // line-height: 50px;
    margin-bottom: 24px;
    padding: 0 1rem;
  }

  .titleDesc {
    text-align: center;
    margin-bottom: 82px;
    font-size: 24px;
    font-weight: 400;
    color: #909093;
    line-height: 34px;
    // padding: 0 300px;
    max-width: 800px;
    margin: 0 auto;
  }

  > .container {
    width: 100%;
    // height: 420px;
    overflow-x: auto;
    overflow-y: hidden;
    // white-space: nowrap;
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    > .cardContainer {
      display: inline-block;
      min-width: 328px;
      max-width: 328px;
      // height: 100%;
      border-radius: 28px;
      margin-right: 40px;
      background: #f1efff;
      padding: 32px 24px;

      &:last-child {
        margin-right: 0;
      }

      > .userContainer {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        > .icon {
          width: 46px;
          height: 46px;
          border-radius: 23px;
        }

        > .userName {
          padding-left: 16px;
          font-size: 20px;
          font-weight: 600;
          color: #0c0c0c;
          line-height: 28px;
        }
      }

      .star {
        font-size: 24px;
        margin-bottom: 24px;
      }

      > .desc {
        background: #fff;
        border-radius: 12px;
        padding: 12px;
        min-height: 254px;
      }
    }
  }
}

@media screen and (min-width: 899px) {
  .content {
    margin-bottom: 184px;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .content {
    margin-bottom: 154px;
    .titleDesc {
      padding: 0 1rem;
    }
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .content {
    margin-bottom: 119px;
    .titleDesc {
      padding: 0 1rem;
    }
  }
}

@media screen and (max-width: 577px) {
  .content {
    margin-bottom: 50px;

    h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }
    .titleDesc {
      padding: 0 1rem;
      font-size: 18px;
    }
  }
}

